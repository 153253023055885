<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <div>
      <label for="de" class="mt-2 block text-sm font-medium">Acompanhar data</label>
      <input v-model="form.data" type="date" name="data" id="data" class="w-1/6 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md" />
    </div>
    <div class="grid grid-cols-12 gap-4 my-2">
      <div class="col-span-12 md:col-span-4 2xl:col-span-3">
        <div v-if="relatorio.chats.length" class="border border-gray-100 rounded overflow-hidden shadow-lg">
          <div class="border-b border-gray-100 px-4 py-2">
            <h3 v-if="relatorio" class="text-lg font-medium text-gray-900">
              Filtrando {{ moment(form.data).format("DD/MM") }} - Conversas totais: <b>{{ relatorio.total.toLocaleString("pt-br") }}</b>
            </h3>
          </div>
          <div class="px-2 py-2">
            <apexchart type="donut" :options="optionsConveras" :series="seriesConversas"></apexchart>
          </div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-6 2xl:col-span-5">
        <div v-if="relatorio.chats.length" class="border border-gray-100 rounded overflow-hidden shadow-lg px-4 py-2">
          <apexchart type="bar" height="290" :options="optionsHorarios" :series="seriesHorarios"></apexchart>
        </div>
      </div>
    </div>
    <h2 class="block text-2xl text-gray-800 font-semibold my-5">Informações conversas <small class="text-sm">(massivo + iniciadas manualmente)</small></h2>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Centro de custo</th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sem Whatsapp</th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Enviados</th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Entregues</th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Lidos</th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Respondidos</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="el in relatorio.chats" :key="el.centrocusto">
                  <td class="text-sm 2xl:text-base px-4 py-3">
                    {{ el.centrocusto ? el.centrocusto : "SEM CENTRO DE CUSTO" }}
                  </td>
                  <td class="text-sm 2xl:text-base font-medium text-gray-800 px-4 py-3">
                    {{ el.totalIniciado.toLocaleString("pt-br") }}
                  </td>
                  <td class="text-sm 2xl:text-base font-medium text-red-700 px-4 py-3">
                    {{ el.semWhatsapp.toLocaleString("pt-br") }}
                  </td>
                  <td class="text-sm 2xl:text-base font-medium text-blue-700 px-4 py-3">
                    {{ el.enviados.toLocaleString("pt-br") }}
                  </td>
                  <td class="text-sm 2xl:text-base font-medium text-green-700 px-4 py-3">
                    {{ el.entregues.toLocaleString("pt-br") }}
                  </td>
                  <td class="text-sm 2xl:text-base font-medium text-yellow-400 px-4 py-3">
                    {{ el.lidos.toLocaleString("pt-br") }}
                  </td>
                  <td class="text-sm 2xl:text-base font-medium text-green-700 px-4 py-3">
                    {{ el.respondidos.toLocaleString("pt-br") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <h2 class="block text-2xl text-gray-800 font-semibold my-5">Informações massivo</h2>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Centro de custo</th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Upload</th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sem Whatsapp</th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Enviado para fila de disparo</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="el in relatorio.massivo" :key="el.centrocusto">
                  <td class="text-sm 2xl:text-base px-4 py-3">
                    {{ el.centrocusto }}
                  </td>
                  <td class="text-sm 2xl:text-base font-medium text-gray-800 px-4 py-3">
                    {{ el.totalUpload.toLocaleString("pt-br") }}
                  </td>
                  <td class="text-sm 2xl:text-base font-medium text-red-700 px-4 py-3">
                    {{ el.semWhatsapp.toLocaleString("pt-br") }}
                  </td>
                  <td class="text-sm 2xl:text-base font-medium text-blue-700 px-4 py-3">
                    {{ el.totalEnviadoFila.toLocaleString("pt-br") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4 my-5">
      <div class="col-span-12 md:col-span-7 2xl:col-span-5">
        <h2 class="block text-2xl text-gray-800 font-semibold">Etiquetas / Tags</h2>
        <apexchart v-if="relatorio.chats.length" type="treemap" :options="optionTags" :series="seriesTags"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      breadcrumb: [{ url: "/relatorios/relatorio-resumo", value: "Relatório resumo de envio" }],
      route: "relatorio-envio",
      moment: moment,
      form: {
        data: moment().startOf("d").format("YYYY-MM-DD")
      },
      seriesConversas: [0, 0, 0, 0],
      seriesTags: [
        {
          data: []
        }
      ],
      optionsConveras: {
        chart: {
          id: "chartGeral",
          type: "donut"
        },
        labels: ["Enviados", "Entregues", "Lidos", "Sem Whatsapp"]
      },
      optionTags: {
        legend: {
          show: false
        },
        chart: {
          toolbar: {
            show: false
          },
          type: "treemap"
        },
        colors: ["#3B93A5", "#F7B844", "#ADD8C7", "#EC3C65", "#CDD7B6", "#C1F666", "#D43F97", "#1E5D8C", "#421243", "#7F94B0", "#EF6537", "#C0ADDB"]
      },
      seriesHorarios: [
        {
          data: []
        }
      ],
      optionsHorarios: {
        chart: {
          toolbar: {
            show: false
          },
          type: "bar"
        },
        plotOptions: {
          bar: {
            columnWidth: "60%"
          }
        },
        colors: ["#00E396"],
        dataLabels: {
          enabled: false
        }
      },
      relatorio: {
        massivo: [],
        chats: [],
        total: 0
      }
    };
  },
  methods: {
    async list() {
      const loader = this.$loading.show({
        container: null,
        canCancel: true
      });
      const listReq = await this.$http.post(`/v1/chatV2/resumo-envios`, this.form);
      if (!listReq.data || !listReq.data.grafico) return loader.hide();
      this.relatorio = listReq.data;
      this.seriesConversas = [listReq.data.grafico.conversas.enviados, listReq.data.grafico.conversas.entregues, listReq.data.grafico.conversas.lidos, listReq.data.grafico.conversas.semWhatsapp];
      this.seriesTags = [{ data: listReq.data.grafico.tags }];
      this.seriesHorarios = [{ name: "Conversas", data: listReq.data.horarios }];
      loader.hide();
    }
  },
  async beforeMount() {
    this.list();
  },
  watch: {
    "form.data": async function () {
      await this.list();
    }
  }
};
</script>
